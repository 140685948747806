// Variables
$primary-color: rgb(60, 132, 244);
$border-color: #e8e8e8;
$link-color: #1677ff;

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#lexical-blog-detail {
  line-height: 1.7;
  font-size: 16px;
  font-family: "Noto Sans", sans-serif !important;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  details:has(div[data-lexical-collapsible-content="true"]) {
    border: 1px solid $border-color;
    padding: 12px;
    border-radius: 8px;

    summary {
      &::marker {
        font-size: 18px;
      }

      > span {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  div[data-lexical-collapsible-content="true"] {
    ol,
    li {
      color: $link-color;
      font-weight: 600;
      font-size: 16px;
      margin: 8px;
      cursor: pointer;
      list-style-type: none;

      @media (max-width: 768px) {
        font-size: 12px;
        margin: 6px;
      }
    }

    .PlaygroundEditorTheme__nestedListItem {
      list-style-type: none;

      ol,
      li {
        font-weight: 400 !important;
        padding: 0;
      }
    }
  }

  .PlaygroundEditorTheme {
    &__ltr {
      text-align: left;
    }

    &__rtl {
      text-align: right;
    }

    &__paragraph {
      margin: 0;
      position: relative;
      padding-bottom: 16px;

      > img {
        display: inline-block;
      }

      @media (max-width: 768px) {
        padding-bottom: 12px;
      }
    }

    &__quote {
      margin: 0 0 10px 20px;
      font-size: 18px;
      color: rgb(101, 103, 107);
      border-left: 4px solid rgb(206, 208, 212);
      padding-left: 16px;

      @media (max-width: 768px) {
        font-size: 12px;
        margin: 0 0 8px 16px;
        padding-left: 12px;
      }
    }

    // Headings
    @for $i from 1 through 6 {
      &__h#{$i},
      &__h#{$i} strong {
        @if $i == 1 {
          font-size: 32px !important;
        } @else if $i == 2 {
          font-size: 24px !important;
        } @else if $i == 3 {
          font-size: 18px !important;
        } @else if $i == 4 {
          font-size: 16px !important;
        } @else if $i == 5 {
          font-size: 16px !important;
        } @else if $i == 6 {
          font-size: 11px !important;
        }
        font-weight: 600;
        margin: 0;
        padding: 6px 0;

        @media (max-width: 768px) {
          @if $i == 1 {
            font-size: 28px !important;
          } @else if $i == 2 {
            font-size: 20px !important;
          } @else if $i == 3 {
            font-size: 16px !important;
          } @else if $i == 4 {
            font-size: 16px !important;
          } @else if $i == 5 {
            font-size: 12px !important;
          } @else if $i == 6 {
            font-size: 10px !important;
          }
          padding: 4px 0;
        }
      }
    }

    &__indent {
      --lexical-indent-base-value: 40px;

      @media (max-width: 768px) {
        --lexical-indent-base-value: 30px;
      }
    }

    &__textBold {
      font-weight: bold;
    }

    &__textItalic {
      font-style: italic;
    }

    &__textUnderline {
      text-decoration: underline;
    }

    &__textStrikethrough {
      text-decoration: line-through;
    }

    &__textUnderlineStrikethrough {
      text-decoration: underline line-through;
    }

    &__textSubscript {
      font-size: 0.8em;
      vertical-align: sub !important;
    }

    &__textSuperscript {
      font-size: 0.8em;
      vertical-align: super;
    }

    &__textCode {
      background-color: rgb(240, 242, 245);
      padding: 1px 0.25rem;
      font-family: Menlo, Consolas, Monaco, monospace;
      font-size: 94%;

      @media (max-width: 768px) {
        font-size: 90%;
      }
    }

    &__hashtag {
      background-color: rgba(88, 144, 255, 0.15);
      border-bottom: 1px solid rgba(88, 144, 255, 0.3);
    }

    &__link {
      color: rgb(33, 111, 219);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__code {
      background-color: rgb(240, 242, 245);
      font-family: Menlo, Consolas, Monaco, monospace;
      display: block;
      padding: 8px 8px 8px 52px;
      line-height: 1.53;
      font-size: 13px;
      margin: 0;
      margin-top: 8px;
      margin-bottom: 8px;
      overflow-x: auto;
      position: relative;
      tab-size: 2;

      @media (max-width: 768px) {
        font-size: 11px;
        padding: 6px 6px 6px 40px;
        line-height: 1.4;
      }

      &:before {
        content: attr(data-gutter);
        position: absolute;
        background-color: #eee;
        left: 0;
        top: 0;
        border-right: 1px solid #ccc;
        padding: 8px;
        color: #777;
        white-space: pre-wrap;
        text-align: right;
        min-width: 25px;

        @media (max-width: 768px) {
          padding: 6px;
          min-width: 20px;
        }
      }
    }

    &__table {
      border-collapse: collapse;
      border-spacing: 0;
      overflow: auto;
      table-layout: fixed;
      margin: 0 auto;
      width: 100%;

      @media (max-width: 768px) {
        margin: 20px 0;
        font-size: 12px;
      }
    }

    &__tableSelection *::selection {
      background-color: transparent;
    }

    &__tableSelected {
      outline: 2px solid $primary-color;
    }

    &__tableCell {
      border: 1px solid #bbb;
      width: 75px;
      min-width: 75px;
      vertical-align: top;
      text-align: start;
      padding: 6px 8px;
      position: relative;
      outline: none;

      @media (max-width: 768px) {
        width: 60px;
        min-width: 60px;
        padding: 4px 6px;
      }
    }

    &__tableCellSortedIndicator {
      display: block;
      opacity: 0.5;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #999;
    }

    &__tableCellResizer {
      position: absolute;
      right: -4px;
      height: 100%;
      width: 8px;
      cursor: ew-resize;
      z-index: 10;
      top: 0;
    }

    &__tableCellHeader {
      background-color: #f2f3f5;
      text-align: start;
    }

    &__tableCellSelected {
      background-color: #c9dbf0;
    }

    &__tableCellPrimarySelected {
      border: 2px solid $primary-color;
      display: block;
      height: calc(100% - 2px);
      position: absolute;
      width: calc(100% - 2px);
      left: -1px;
      top: -1px;
      z-index: 2;
    }

    &__tableCellEditing {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      border-radius: 3px;
    }

    &__tableAddColumns {
      position: absolute;
      top: 0;
      width: 20px;
      background-color: #eee;
      height: 100%;
      right: -25px;
      animation: table-controls 0.2s ease;
      border: 0;
      cursor: pointer;

      &:after {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
      }

      &:hover {
        background-color: #c9dbf0;
      }
    }

    &__tableAddRows {
      position: absolute;
      bottom: -25px;
      width: calc(100% - 25px);
      background-color: #eee;
      height: 20px;
      left: 0;
      animation: table-controls 0.2s ease;
      border: 0;
      cursor: pointer;

      &:after {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
      }

      &:hover {
        background-color: #c9dbf0;
      }
    }

    @keyframes table-controls {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &__tableCellResizeRuler {
      display: block;
      position: absolute;
      width: 1px;
      background-color: $primary-color;
      height: 100%;
      top: 0;
    }

    &__tableCellActionButtonContainer {
      display: block;
      right: 5px;
      top: 6px;
      position: absolute;
      z-index: 4;
      width: 20px;
      height: 20px;

      @media (max-width: 768px) {
        right: 3px;
        top: 3px;
        width: 16px;
        height: 16px;
      }
    }

    &__tableCellActionButton {
      background-color: #eee;
      display: block;
      border: 0;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      color: #222;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }

      @media (max-width: 768px) {
        width: 16px;
        height: 16px;
      }
    }

    &__characterLimit {
      display: inline;
      background-color: #ffbbbb !important;
    }

    &__ol1 {
      padding: 0;
      margin: 0;
      list-style-position: inside;
    }

    &__ol2 {
      padding: 0;
      margin: 0;
      list-style-type: upper-alpha;
      list-style-position: inside;
    }

    &__ol3 {
      padding: 0;
      margin: 0;
      list-style-type: lower-alpha;
      list-style-position: inside;
    }

    &__ol4 {
      padding: 0;
      margin: 0;
      list-style-type: upper-roman;
      list-style-position: inside;
    }

    &__ol5 {
      padding: 0;
      margin: 0;
      list-style-type: lower-roman;
      list-style-position: inside;
    }

    &__ul {
      padding: 0;
      margin: 0;
      list-style-position: inside !important;
      list-style: unset;
    }

    &__listItem {
      margin: 0 24px;

      @media (max-width: 768px) {
        margin: 0 16px;
      }
    }

    &__listItemChecked,
    &__listItemUnchecked {
      position: relative;
      margin-left: 8px;
      margin-right: 8px;
      padding-left: 24px;
      padding-right: 24px;
      list-style-type: none;
      outline: none;

      @media (max-width: 768px) {
        margin-left: 6px;
        margin-right: 6px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__listItemChecked {
      text-decoration: line-through;
    }

    &__listItemUnchecked:before,
    &__listItemChecked:before {
      content: "";
      width: 16px;
      height: 16px;
      top: 2px;
      left: 0;
      cursor: pointer;
      display: block;
      background-size: cover;
      position: absolute;

      @media (max-width: 768px) {
        width: 16px;
        height: 16px;
      }
    }

    &__listItemUnchecked[dir="rtl"]:before,
    &__listItemChecked[dir="rtl"]:before {
      left: auto;
      right: 0;
    }

    &__listItemUnchecked:focus:before,
    &__listItemChecked:focus:before {
      box-shadow: 0 0 0 2px #a6cdfe;
      border-radius: 2px;
    }

    &__listItemUnchecked:before {
      border: 1px solid #999;
      border-radius: 2px;
    }

    &__listItemChecked:before {
      border: 1px solid $primary-color;
      border-radius: 2px;
      background-color: $primary-color;
      background-repeat: no-repeat;
    }

    &__listItemChecked:after {
      content: "";
      cursor: pointer;
      border-color: #fff;
      border-style: solid;
      position: absolute;
      display: block;
      top: 6px;
      width: 3px;
      left: 7px;
      right: 7px;
      height: 6px;
      transform: rotate(45deg);
      border-width: 0 2px 2px 0;

      @media (max-width: 768px) {
        top: 5px;
        width: 2px;
        left: 6px;
        right: 6px;
        height: 5px;
      }
    }

    &__nestedListItem {
      list-style-type: none;

      &:before,
      &:after {
        display: none;
      }
    }

    &__tokenComment {
      color: slategray;
    }

    &__tokenPunctuation {
      color: #999;
    }

    &__tokenProperty {
      color: #905;
    }

    &__tokenSelector {
      color: #690;
    }

    &__tokenOperator {
      color: #9a6e3a;
    }

    &__tokenAttr {
      color: #07a;
    }

    &__tokenVariable {
      color: #e90;
    }

    &__tokenFunction {
      color: #dd4a68;
    }

    &__mark {
      background: rgba(255, 212, 0, 0.14);
      border-bottom: 2px solid rgba(255, 212, 0, 0.3);
      padding-bottom: 2px;

      &.selected {
        background: rgba(255, 212, 0, 0.5);
        border-bottom: 2px solid rgba(255, 212, 0, 1);
      }
    }

    &__markOverlap {
      background: rgba(255, 212, 0, 0.3);
      border-bottom: 2px solid rgba(255, 212, 0, 0.7);

      &.selected {
        background: rgba(255, 212, 0, 0.7);
        border-bottom: 2px solid rgba(255, 212, 0, 0.7);
      }
    }

    &__embedBlock {
      user-select: none;
    }

    &__embedBlockFocus {
      outline: 2px solid $primary-color;
    }

    &__layoutContainer {
      display: grid;
      gap: 10px;
      margin: 10px 0;

      @media (max-width: 768px) {
        gap: 8px;
        margin: 8px 0;
      }
    }

    &__layoutItem {
      border: 1px dashed #ddd;
      padding: 8px 16px;

      @media (max-width: 768px) {
        padding: 6px 12px;
      }
    }
  }

  div[data-lexical-tweet-id] > div {
    display: inline-block;
    min-width: 550px;
  }
}

