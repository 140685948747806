@tailwind base;
@tailwind components;
@tailwind utilities;

$gray25: #fcfcfd;
$gray50: #f9fafb;
$gray100: #f2f4f7;
$gray200: #eaecf0;
$gray300: #d0d5dd;
$gray400: #98a2b3;
$gray500: #667085;
$gray600: #475467;
$gray700: #344054;
$gray800: #212c3f;
$gray900: #101828;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --font-dmsans: "Noto Sans", sans-serif;
}


body {
  font-family: var(--font-dmsans) !important;
  color: rgb(var(--foreground-rgb));
  background: #ffffff;

  * {
    &::-webkit-scrollbar {
      height: 8px;
      width: 5px;
      background-color: $gray50;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2.5px;
      background-color: $gray300;

      &:hover {
        background-color: $gray400;
      }
    }
  }
}

.slick-slider {
  li {
    width: 100%;
  }
}


.flash-green {
  animation: flashGreenText 1s ease-in-out;
}

.flash-red {
  animation: flashRedText 1s ease-in-out;
}

@keyframes flashGreenText {
  0% {
    color: #52c41a;
  }
  100% {
    color: inherit;
  }
}

@keyframes flashRedText {
  0% {
    color: #ff4d4f;
  }
  100% {
    color: inherit;
  }
}

.slick-next, .slick-prev {
  display: none !important;
}

.select-diversity-custom {
  .ant-select-selector {
    border: none !important;
  }

  .ant-select-selection-item, .ant-select-arrow {
    color: #1A56DB !important;
  }
}

.antd-select-custom-border {
  .ant-select-selector {
    border: none !important;
  }
}
